#menuWraper{
    position: fixed;
    
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;

    background-color: #ffffff88;
    box-shadow: 0 .5rem 1rem #ffffffaa;
    backdrop-filter: blur(.5rem);
    /* background-color: #364869cc; */
    /* box-shadow: 0 .5rem 1rem var(--kurkaNavyBlue); */
    
    /* border: 1px solid red; */
}
/* #menuWraper::before{
    content: '';
    position: fixed;
    top: 0;
    width: 100vw;
    height: 9rem;
    backdrop-filter: blur(.5rem);
    mask: linear-gradient(black 0, black 7rem, transparent 8.6rem, transparent 100%);
    border: 1px soli green;
} */
#menuWraper.full{
    top: 0;
    padding-top: 1rem;
    padding-bottom: 2rem;
    z-index: 100000;
}
#menuWraper.mobile{
    bottom: 0;
    /* top: calc(100svh - 4.5rem); */
    /* padding-bottom: 1.5rem; */
    /* box-shadow: 0 -.6rem .5rem var(--kurkaNavyBlue); */
    /* top: calc(100svh - 4rem) */
}



#menu{
    /* background-color: #ffffff; */
    /* background-color: var(--kurkaNavyBlue); */
    background: linear-gradient(135deg, #82a2e6 0%,#364869 100%);
    
    max-width: 100vw;
    /* height: 48px; */
    /* border-radius: 1rem; */
    padding-left: 1rem;
    padding-right: 1rem;
    /* overflow: hidden; */
    text-decoration: none;

    display: flex;
    justify-content: center;
}
#menuWraper.full #menu{
    border-radius: 1rem;
}
#menuWraper.mobile #menu{
    width: 100vw;
    justify-content: space-evenly;
}
#menuWraper.mobile #menu.standalone{
    padding-bottom: .75rem;
    /* bottom: -.5rem; */
}



.menuItem{
    position: relative;
    /* height: 48px; */
    /* width: 128px; */
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    cursor: pointer;

}
#menuWraper.full .menuItem{
    padding-left: 1rem;
    padding-right: 1rem;
}
#menuWraper.mobile .menuItem span{
    display: none;
}
#menuWraper.full .menuItem span{
    position: absolute;
    display: block;
    height: 1.5rem;
    width: 8rem;
    text-align: center;
    pointer-events: none;

    /* overflow: hidden; */
    transition: all .3s ease-in-out;
    
    bottom: -1rem;
    /* color: #ffffff; */
    color: var(--kurkaNavyBlue);
    opacity: 0;
    font-size: .3rem;
    
    /* border: 1px solid red; */
    
}
.active span{
    transition-delay: .5s;
}
/* #menuWraper.full .active span, */
#menuWraper.full .menuItem:hover span
{
    opacity: 1;
    bottom: -2rem;
    font-size: 1rem;
}
/* .menuItem:hover span{
    transition-delay: 0;
} */
/* .menuItem:hover span, .active span{
    display: block;
} */

.MenuItemIcon{
    margin: 1rem;
    width: 2rem;
    height: 2rem;
    position: relative;
    transition: all .3s ease-in-out;    
    top: 0;

    fill: #fff;
    filter: drop-shadow(2px 2px 3px #647189);
}
#menuWraper.full .active .MenuItemIcon{
    top: .8rem;
}
#menuWraper.mobile .active .MenuItemIcon{
    top: -.8rem;
}

.active .MenuItemIcon{
    /* fill:#ffffff; */
    fill: var(--kurkaNavyBlue)
}

.menuAccent{
    /* background-color: var(--kurkaNavyBlue); */
    background-color: #fff;
    position: absolute;
    width: 4rem;
    height: 0rem;
    
    /* border: 1px solid red; */
    transition: all .3s ease-in-out;
}
#menuWraper.full .menuAccent{
    left: 1rem;
    border-radius: 2rem 2rem 0 0;
    bottom: 0rem;

}
#menuWraper.mobile .menuAccent{
    left: 0;
    border-radius: 0 0 2rem 2rem;
    top: -1px;
}
.active .menuAccent{
    /* background-color: var(--kurkaNavyBlue); */
    /* background-color: blue; */
    /* bottom: -0rem; */
    height: 3rem;
}
.menuAccentL, .menuAccentR{
    position: absolute;
    /* border: 1px solid green; */
    width: 2rem;
    height: 0rem;
    overflow: hidden;
    transition: all .3s ease-in-out;
    transition-delay: 0;
}
#menuWraper.full .menuAccentL, #menuWraper.full .menuAccentR{
    bottom: 0rem;

}
#menuWraper.mobile .menuAccentL, #menuWraper.mobile .menuAccentR{
    top: -1px;

}
.menuAccentL{
    left: 0;
}
.menuAccentR{
    right: 0;
}
#menuWraper.mobile .menuAccentL{
    left: -1rem;
}
#menuWraper.mobile .menuAccentR{
    right: -1rem;
}
.active .menuAccentL, .active .menuAccentR{
    height: 1rem;
    transition-delay: .2s;
}
.menuAccentL div, .menuAccentR div{
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 2.5rem;
    border: 1rem solid var(--kurkaNavyBlue);
    border: 1rem solid #fff;
    /* border: 1rem solid #000000aa; */
    /* top: -2rem; */
}
#menuWraper.full .menuAccentL div, #menuWraper.full .menuAccentR div{
    top: -2rem;
}
#menuWraper.mobile .menuAccentL div, #menuWraper.mobile .menuAccentR div{
    bottom: -2rem;
}
.menuAccentL div{
    left: -2rem;
}
.menuAccentR div{
    right: -2rem;
}

#menu a {
    text-decoration: none;
}