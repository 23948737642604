.table{
    width: 100%;
}

.right40{
    width: 40%;
}

@keyframes warnAnimation {
    0%{
        background-color: transparent;
    }
    50%{
        background-color: salmon;
    }
    100%{
        background-color: transparent;
    }
}
.inputWarn{
    animation: warnAnimation .3s;
}