


.OrderDetailsPayementTable{
    width: 100%;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
}
.OrderDetailsPayementTable td{
    text-align: right;
    text-align-last: right;
}
.OrderDetailsPayementTable tr td:first-of-type{
    text-align: left;
    text-align-last: left;
}
.OrderDetailsPayementTable tr td:last-of-type{
    width: .5rem;
}
.tableTdRight{
    text-align: right;
}
.OrderDetailsPayementSum{
    border-top: .2rem solid var(--kurkaNavyBlue);
    /* height: 1rem; */
}

.orderDetailsTable{
    width: 100%;
    /* border: 1px solid red; */
    text-align: right;
    text-align-last: right;
}
.orderDetailsTable td{
    vertical-align: top;
    line-height: 1rem;
    padding-bottom: .5rem;
}
.orderDetailsTable td:last-of-type{
    text-align: left;
    text-align-last: left;
    width: 50%;
}