#newLaundry .tileCenter{
    text-align: center;
}

/* select @app.css */

/* select:first-of-type{
    margin-top: 1rem;
} */

#dateTimeDiv div{
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    /* outline: 1px solid lime; */
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
}
#dateTimeDiv select{
    display: inline-block;
    width: auto;
    border-radius: 0;
    flex-grow: 1;
}
@media only screen and (min-width: 600px) {
    #dateTimeDiv select{
        width: 33%;
        width: calc(100% / 3);
    }
}
    #dateTimeDiv select:first-of-type{
        border-right-width: 0;
        border-radius: .75rem 0 0 .75rem;
    }
    #dateTimeDiv select:last-of-type{
        border-left-width: 0;
        border-radius: 0 .75rem .75rem 0;
    }

/* #RugSplitDiv *{
    outline: 1px solid lime;
} */

#RugSplitDiv{
    display: flex;
    position: relative;
    margin-top: .24rem;
    margin-bottom: .25rem;
    /* outline: 1px solid salmon; */
    /* border: 1px solid red; */
}
#RugSplitX{
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--kurkaNavyBlue);
    height: 2.25rem;
    width: 2rem;
    margin-top: 0;
    border-top: 2px solid var(--kurkaNavyBlue);
    border-bottom: 2px solid var(--kurkaNavyBlue);
    margin-left: -1px;
    margin-right: -1px;

    /* outline: 1px solid lime; */
}
#RugSplitDiv input{
    /* width: auto; */
    margin: 0;

}
#RugSplitDiv input:first-of-type{
    border-right: none;
    border-radius: .75rem 0 0 .75rem;
}
#RugSplitDiv input:last-child{
    border-left: 0;
    border-radius: 0 .75rem .75rem 0;
}
#RugSplitX:after, #RugSplitDiv:after{
    content: 'cm';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 1.5rem;
    top: 2px;
    height: 2rem;
    color: #666;
    font-size: 0.75em;
    /* outline: 1px solid red; */
}
#RugSplitX:after{
    right: calc(100% + 1.5rem);
    top: 0;
}






































/* 
#addressSelect, #daySelect{
    width: 100%;
    display: grid;
    padding: .5rem 0 .5rem 0;
}
#daySelect{
    grid-template-columns: repeat(auto-fill, minmax(100px, 50%));
}

button{
    all: unset;
    writing-mode: unset;
    display: inline-block;
    padding: .25rem;


    border: 2px solid #fff;
    border-radius: .5rem;
    margin-bottom: .5rem;
    max-height: 4rem;

    transition: all .5s ease-in-out;
}

.sel button{
    max-height: 0;
    padding: 0;
    padding-left: .25rem;
    padding-right: .25rem;
    border-width: 0;
    margin-bottom: 0;
    opacity: 0;
}
.sel button.sel{
    max-height: 4rem;
    padding: 0rem;
    opacity: 1;
}

pre{
    max-width: 100%;
} */