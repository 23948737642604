.slider{
    position: relative;
    width: 100%;
    overflow-x: hidden;
    /* outline: 1px solid lime; */
}
.sliderInerDiv{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 10%;

    transition: left .5s ease-in-out;
    left: 0;
    /* outline: 1px solid salmon; */
}
.sliderInerDiv div{
    min-width: 100%;
}

.sliderInerDiv.s2{
    left: -110%;
}
.sliderInerDiv.s3{
    left: -220%;
}

.sliderButtons{
    display: flex;
    justify-content: center;
}
.sliderButtons button{
    all: unset;
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--kurkaNavyBlue);
    border-radius: .5rem;
    margin-left: .5rem;
    margin-right: .5rem;
    cursor: pointer;
    transition: background-color .5s ease-in-out;
}
.sliderButtons button.active{
    background-color: var(--kurkaNavyBlue);
}