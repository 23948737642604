#bubleWraper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    /* background: var(--kurkaNavyBlue); */
    z-index: -100;
    /* border: 1px solid purple; */
}

.bubleContainer{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
}
.buble {
    position: absolute;
    /* opacity: .2; */
    -webkit-animation: bubleAnimation ease-in;
    -moz-animation: bubleAnimation ease-in;
    -ms-animation: bubleAnimation ease-in;
    -o-animation: bubleAnimation ease-in;
    animation: bubleAnimation ease-in;

    -webkit-animation-duration: 15s;
    -moz-animation-duration: 15s;
    -ms-animation-duration: 15s;
    -o-animation-duration: 15s;
    animation-duration: 15s;

    /* border: 10px solid red; */
}
.buble svg{
    /* opacity: .3; */
    fill: var(--kurkaNavyBlue);
    /* fill: red !important; */
}
#bubleContainer1 svg, #bubleContainer2 svg{
    filter: blur(.25vh);
}
#bubleContainer3 svg{
    filter: blur(.75vh);
}
#bubleContainer4 svg{
    filter: blur(2vh);
}

@keyframes bubleAnimation {
    0% {
        scale: 0.3;
        opacity: 0;


        transform: translateX(0);
    }
    15% { 
        opacity: 1;
        scale: 1;
    }
    85% {
        opacity: 1;
        scale: 1;
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -o-filter: blur(0);
        -ms-filter: blur(0);
        filter: blur(0);
    }
    100% {
        scale: 1.5;
        opacity:0;
        -webkit-filter: blur(5vw);
        -moz-filter: blur(5vw);
        -o-filter: blur(5vw);
        -ms-filter: blur(5vw);
        filter: blur(5vw);


        transform: translateY(-70vh);
    }
}

#bubleStats{
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    color: red;
    border: 2px solid red;
}