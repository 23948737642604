.loginWraper{
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* border: 1px solid red; */
    
    /* position: fixed; */
    /* left: .5rem; */
    margin-bottom: 1rem;
}
.contentWraperMobile.loginWraper{
    min-height: calc(100svh - 5rem);
}
#loginForm{
    width: 300px;
    border-radius: 1rem;
    /* padding: 1rem; */
    text-align: center;
    overflow: hidden;
    padding-bottom: 1rem;
    
    /* background-color: #ffffff; */
    /* backdrop-filter: blur(.75rem) grayscale(100%) invert(100%) contrast(200%); */
    box-shadow: inset 0 0 3px var(--kurkaNavyBlue), 1px 1px 3px rgba(0, 0, 0, .2);
}
#loginForm h2{
    color: #fff;
}
.logo{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: .5rem; */
    margin-top: 1rem;
    fill: #fff;
}

#loginInputWraper {
    width: 100%;
    width: calc(100% - 2rem);
    /* border: 1px solid red; */
    /* height: 3rem; */
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;

    margin-bottom: 5rem;

    /* border: 1px solid lime; */
}
#displayLogin, #login{
    position: absolute;
    top: 0;
    margin-top: 0;
    /* height: 2rem; */
    width: 100%;
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: center;
    
    padding-top: .25rem;
    padding-bottom: .25rem;
    /* color: #ffffff; */
    
}
#displayLogin{
    left: 2rem;
    /* border-radius: 1rem 0 0 1rem; */
    height: 3rem;
    width: calc(100% - 4rem);
    /* background: #ffffff; */

    display: flex;
    justify-content: center;
    overflow: hidden;
    border-top: 2px solid var(--kurkaNavyBlue);
    border-bottom: 2px solid var(--kurkaNavyBlue);
    padding: .35rem;
}
#displayLogin span{
    display: block;
    position: relative;
    top: -.7rem;
    /* border: 1px solid red; */
    width: .9rem;
    margin-right: .1rem;
    margin-left: .1rem;
    height: 2rem;
    text-align: center;
    /* opacity: .3; */
    transition: all .3s ease-in-out;
    color: #ffffff;
    border-bottom: 2px solid var(--kurkaNavyBlue);
}
#displayLogin span:nth-child(3), #displayLogin span:nth-child(6){
    margin-right: .5rem;
}
#displayLogin span.filed{
    /* opacity: 1; */
    top: 0;
    color: var(--kurkaNavyBlue);
    border-bottom: 2px solid transparent;
}
#displayLogin.focused span.unfiled{
    animation: bounce 2.5s forwards infinite;
    animation-delay: .25s;
}
@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    10%{
        transform: translateY(-.2rem);
    }
    20%{
        transform: translateY(0);
    }
    30%{
        transform: translateY(-.1rem);
    }
    40%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-.05rem);
    }
    60%{
        transform: translateY(0);
    }
    
}
#login{
    color: #ffffff;
    /* background-color: transparent; */
    height: 3rem;
    /* top: 3.5rem; */
    right: 0;
    border-radius:1rem;
    border: 2px solid var(--kurkaNavyBlue);
    caret-color: transparent;

    /* outline: 2px solid red; */
    /* opacity: 0; */
}
#loginForm h2{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
#loginForm p{
    font-size: .8rem;
    /* color: #999999; */
    color: #fff;
}


/* .inputIcon{
    position: absolute;
    left: .5rem;
    top: .5rem;
    height: 2rem;
    width: 2rem;

    fill: var(--kurkaNavyBlue);
} */





.step p{
    padding-left: 1rem;
    padding-right: 1rem;
}


#smsWrapper, #displaySmsCode{
    position: relative;
    height: 3rem;
    /* border:1px solid green; */
    display: flex;
    justify-content: center;
}
#smsCode{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3rem;
    border: none;
    /* color: #ffffff; */
    opacity: 0;
}
.smsDigit{
    border: 2px solid var(--kurkaNavyBlue);
    width: 2rem;
    /* line-height: 2rem; */
    font-size: 1.75rem;
    border-radius: .5rem;
    margin-left: .2rem;
    margin-right: .2rem;
    background-color: #fff;
    color: var(--kurkaNavyBlue);
    overflow: hidden;
    /* display: flex;
    justify-content: center; */
    /* align-items: center; */
}
.smsDigit span{
    display: block;
    position: relative;
    top: -.6rem;
    left: .4rem;  
    height: 2rem;
    /* border: 1px solid red; */
    width: .9rem;
    text-align: center;
    color: #ffffff;
    border-bottom: 2px solid var(--kurkaNavyBlue);
    transition: all .2s ease-in-out;

    /* background-color: red; */
}
#displaySmsCode .filed{
    top: .3rem;
    /* height: 2rem; */
    color: var(--kurkaNavyBlue);
    border-bottom: 2px solid transparent;
}
#displaySmsCode.focused .unfieled{
    animation: bounce 2.5s forwards infinite;
    animation-delay: .25s;
}

#smsWrapper span:nth-child(3){
    margin-right: .6rem;
}




#stepWraper{
    position: relative;
    height: auto;
    transition: all .3s ease-in-out;
    transition-delay: 5s;
    /* border: 1px solid salmon; */
}
.step, .inactive{
    /* border: 1px solid red; */
    overflow: hidden;
    transition: all .3s ease-in-out;   
}
.step{
    position: relative;
    top: 0;
    max-height: 1000px;
}
.inactive{
    position: absolute;
    width: 100%;
    /* top: 0; */
    opacity: 0;
    scale: .7;
    max-height: 0;
}


/* #login:focus{
    background: red;
} */