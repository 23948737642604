#loaderContainer{
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* border: 1px solid green; */
  /* overflow: hidden; */

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100000;
  /* border: 2px solid lime; */

  --loaderDim: min(80vw, 80vh, 300px);
}

#loaderLogo{
  position: relative;
  width: 300px;
  height: 300px;
  width: var(--loaderDim);
  height: var(--loaderDim);
  
  /* border: 2px solid red; */
  transition: all 1s ease-in-out;
  /* transition-delay: 3s; */
  z-index: 100000000000;
}
#loaderLogo.fadeOut{
  scale: 2;
  opacity: 0;
  filter: blur(10vw);
}

#Le1, #Le2, #Le3, #Le4{
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  width: var(--loaderDim);
  height: var(--loaderDim);
  fill: var(--kurkaNavyBlue);

  filter: drop-shadow(2px 2px 3px #647189);

  /* border: 3px solid purple; */
}


#Le1{
  animation: spin .6s linear infinite;
}
#Le2{
  animation: spin .8s linear infinite reverse;
}
#Le3{
  opacity: 0;
}
#Le4{
  opacity: 0;
}


#Le1.ending, #Le1.fadeOut{
  animation-iteration-count: 1;
}
#Le2.ending, #Le2.fadeOut{
  animation-iteration-count: 1;
}
#Le3.ending, #Le3.fadeOut{
  opacity: 1;
  animation: fadeinspin .4s ease-out;
  -webkit-animation: fadeinspin .4s ease-out;
  -ms-animation: fadeinspin .4s ease-out;
}
#Le4.ending, #Le4.fadeOut{
  opacity: 1;
  animation: fadein .5s ease-out;
  -webkit-animation: fadein .5s ease-out;
  -ms-animation: fadein .5s ease-out;
}

@keyframes spin {
  0%{
      rotate: 0;
  }
  100%{
      rotate: 360deg;
  }
}
@keyframes fadeinspin {
  0%{
      opacity: 0;
      rotate: -45deg;
  }
  100%{
      opacity: 1;
      rotate: 0;
  }
}
@keyframes fadein {
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}


@-webkit-keyframes spin {
  0%{
      rotate: 0;
  }
  100%{
      rotate: 360deg;
  }
}
@-webkit-keyframes fadeinspin {
  0%{
      opacity: 0;
      rotate: -45deg;
  }
  100%{
      opacity: 1;
      rotate: 0;
  }
}
@-webkit-keyframes fadein {
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}







/* tylko do testów */
/* .loaderPre{ 
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;

  border: 2px solid salmon;
} */