:root{
  --kurkaNavyBlue: #364869;
  --kurkaLightBlue: #647189;
  --kurkaAniaBlue: #82A2E6
}
::-webkit-scrollbar-track {
  background: var(--kurkaNavyBlue); 
}
::selection {
  background: var(--kurkaLightBlue)
}
@font-face {
  font-family: system-ui;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}
* {
  -webkit-tap-highlight-color: transparent;
  font-family: 'Barlow', sans-serif;
  font-weight: 300;
  
  box-sizing: border-box;
  /* cursor: default; */
}
span, div, p, pre{
  cursor: default;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
html, body{
  padding: 0;
  margin: 0;
  /* font-family: San Francisco; */
  /* font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  font-family: "system-ui";


  /* ///////////////////////////// */
  /* // disable for dev purpous // */
  /* user-select: none; */
  /* ///////////////////////////// */
}
#MyRouter{
  display: flex;
  justify-content: center;
  width: 100%;
  /* border: 1px solid white; */
  
  transition: opacity .4s ease-in-out;
  z-index: 10;
}
.contentWraper, .contentWraperMobile{
  width: 100vw;
  max-width: 750px;
  width: calc(100vw - 1rem);
  /* outline: 1px solid lime; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  
  flex-direction: column;
  
  /* padding: 1rem; */
  
  /* border: 3px solid red; */
}
.contentWraper{
  width: calc(100vw - 3rem);
  margin-top: 7.75rem;
  /* min-height: calc(100svh - 9rem); */
  /* margin-bottom: 25svh; */
}
.contentWraperMobile{
  min-height: calc((100vh - 5rem));
  /* position: absolute; */
  /* top: 1px; */
  margin-bottom: 6rem;
}

.linc {
  text-decoration: underline;
  color: var(--kurkaNavyBlue);
}

.checkboxWraper, .checkboxWraperBold{
  /* border: 1px solid red; */
  position: relative;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: left;

  padding: .75rem;
  padding-right: 4.5rem;
  padding-left: 1rem;
  text-align: left;
}
.checkboxWraper{
  padding-left: 1rem;
}
.checkboxWraperBold{
  font-weight: bold;
}
.checkboxWraper input,
.checkboxWraperBold input{
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: .5rem;
  height: .5rem;
  /* opacity: 0; */
}

.checkboxChecked, .checkbox{
  position: absolute;
  top: .5rem;
  top: calc((50% - 1rem));
  right: 1rem;
  border: 2px solid var(--kurkaNavyBlue);
  border-radius: 1rem;
  height: 2rem;
  width: 3rem;
  overflow: hidden;
  transition: all .2s ease-in-out;
  transition-delay: .1s;
}
.checkbox{
  background-color: #fff;
}
.checkboxChecked{
  background-color: var(--kurkaLightBlue);
}

.checkboxChecked div, .checkbox div{
  position: absolute;
  display: block;
  top: 1px;
  width: 1.6rem;
  width: calc(2rem - 6px);
  height: 1.6rem;
  height: calc(2rem - 6px);
  border-radius: 1rem;
  transition: all .1s ease-in-out;
}
.checkbox div{
  left: 1px;
  background-color: #fff;
  border: 2px solid var(--kurkaNavyBlue);
}
.checkboxChecked div{
  left: 1.1rem;
  /* left */
  border: 1px solid #fff;
  background-color: var(--kurkaNavyBlue);
}

h1, h2{
  /* color: #fff; */
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  /* margin-top: 1.75em; */
  line-height: 1em;
  text-shadow: 0 0 3px #fff;
}
h1, h2, h3{
  /* text-shadow: 2px 2px 3px var(--kurkaLightBlue); */
  color: var(--kurkaNavyBlue);
  font-weight: bold;
}
/* h1:first-of-type{
  margin-top: 1rem;
} */


pre{
  max-width: 100%;
  overflow-x: hidden;
  background-color: salmon;
}



.tile, .tileCenter{
  display: block;
  /* background-color: #fff; */
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;

  text-decoration: none;
  color: #000;
}
.tileCenter{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* text-align: justify; */
  text-align: center;
  text-align-last: center;
  line-height: 1.5rem;
}
.tileCenter h3{
  color: #fff;
  margin: 0;
}



.tileIconed, .tileIconedWarning, .tile, .tileCenter{
  /* backdrop-filter: blur(.75rem) grayscale(100%) invert(100%) contrast(220%); */
  /* background-color: #fff; */
  /* box-shadow: inset 0 0 3px var(--kurkaNavyBlue), 1px 1px 3px rgba(0, 0, 0, .2); */
  border: 2px solid var(--kurkaNavyBlue);

  display: block;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;

  /* border: 1px solid red; */

  text-decoration: none;
  /* color: #000; */
}
.tileIconedWarning{
  border: 3px solid salmon;
}
.tileIconed h3{
  margin: 0;
  padding: 0;
  /* margin-bottom: .1rem; */
  color: #fff;
}
.tileIconed, .tileIconedWarning{
  position: relative;
  padding-left: 4rem;
  min-height: 4rem;
  overflow: hidden;
}
.tileIconedIcon, .tileIconedIconTop{
  
  /* fill: var(--kurkaNavyBlue); */
  fill: #fff;
  /* filter: drop-shadow(2px 2px 3px #647189); */
  filter: drop-shadow(1px 1px 1px #fff);
}
.lesVisibleTile .tileIconedIconTop{
  fill: var(--kurkaNavyBlue);
}
.tileIconedIcon{
  height: 2rem;
  width: 2rem;
  position: absolute;
  left: 1rem;
  /* top: 1rem; */
  top: calc(50% - 1rem);
}
.tileIconedIconTop{
  height: 3.5rem;
  width: 3.5rem;
  margin-bottom: .5rem;
}
.tileArrow{
  position: absolute;
  top: 1rem;
  top: calc(50% - .5rem);
  right: 1rem;

  height: 1rem;
  width: 1rem;
  /* fill: var(--kurkaNavyBlue); */
  fill: #fff;
  transition: all .2s ease-in-out;
}
.tileIconed:hover .tileArrow{
  scale: 1.5;
}
.buttonDiv{
  text-align: center;
  /* border:1px solid red; */
  margin: .5rem;
  /* padding: 1rem; */

  line-height: 3rem;
}
.button, .buttonWarn{
  border: 2px solid var(--kurkaNavyBlue);
  padding: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: .75rem;
  line-height: 1rem;
  font-size: 1rem;
  font-style: inherit;
  color: var(--kurkaNavyBlue);
  text-decoration: none;
  background-color: #fff;

  /* box-shadow: inset 0 -.25rem .25rem var(--kurkaNavyBlue); */
  transition: all .2s ease-in-out;
  cursor: pointer;

  max-width: 100%;
  margin-left: .25rem;
  margin-right: .25rem;
}
.buttonWarn{
  border: 3px solid salmon;
}

.button:hover{
  /* box-shadow: inset 0 -.5rem .25rem var(--kurkaNavyBlue); */
  background-color: var(--kurkaLightBlue);
  color: #fff;
}
.buttonWarn:hover{
  color: #fff;
  background-color: salmon;
}

.justify{
  text-align: justify;
}


.lesVisibleTile{
  min-height: 7rem;
  border: .15rem dashed var(--kurkaLightBlue);
  border-radius: 1rem;
  padding: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--kurkaLightBlue);
  font-size: 1rem;
  text-align: center;
  text-align-last: center;

  background-color: #ffffff66;
  backdrop-filter: blur(.5rem);
  line-height: 1.6em;
  margin-bottom: 1rem;
}
.lesVisibleTile span, .lesVisibleTile div{
  display: block;
  max-width: 500px;
}



.warning{
  border: 3px solid salmon;
  background-color: #fff;
  font-size: 1.5rem;
  text-align: center;
  color: salmon;
  padding: 1rem;
  border-radius: 1rem;
}

input, select{
  all: unset;
  display: block;
  width: 100%;
  border-radius: .75rem;
  border: 2px solid var(--kurkaNavyBlue);
  background-color: #fff;
  color: var(--kurkaNavyBlue);
  padding: .25rem;
  box-sizing: border-box;
  margin-bottom: .5rem;
  margin-top: .25rem;

}

.bold{
  font-weight: bold;
}










.tileIconed, .tileIconedWarning, .tile, .tileCenter, .OrderTile, #loginForm{

  /* backdrop-filter: blur(.75rem); */
  /* backdrop-filter: blur(.75rem) grayscale(100%) invert(100%) contrast(220%); */

  background-color: #ffffff;
  /* background-color: var(--kurkaNavyBlue); */
  background: linear-gradient(135deg, #82a2e6 0%,#364869 100%);
  color: #fff;
}
input, select, button, a.button{
  height: 2.25rem;
  display: inline-block;
}
h1, h2, h3{
  /* color: #fff; */
  /* color: var(--kurkaNavyBlue); */
}


.linc{
  all: unset;
  cursor: pointer;

  text-decoration: underline;
}

nobr{
  white-space: nowrap;
}




.zaslepkaWraper{
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.zaslepka{
  display: flex;
  align-items: center;
  padding: 2rem;
  /* border: 1px solid red; */
}
@media screen and (max-width: 600px) {
  .zaslepka{
    flex-direction: column;
  }
}
.zaslepkaLogo{
  fill: var(--kurkaNavyBlue);
  width: min(75vw, 300px);
}
.zaslepkaText{
  text-align: center;
  /* border: 1px solid red; */
  border: none
}
.zaslepka p{
  margin: .25rem;
}