.OrderTile{
    display: block;
    position: relative;
    margin-bottom: 1rem;
    border-radius: 1rem;
    
    text-decoration: none;
    /* color: #000; */
    padding: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    /* text-align: center; */
    
    
    /* background: #fff; */
    /* backdrop-filter: blur(.75rem) grayscale(100%) invert(100%) contrast(220%); */
    /* box-shadow: inset 0 0 3px var(--kurkaNavyBlue), 1px 1px 3px rgba(0, 0, 0, .2); */
    border: 2px solid var(--kurkaNavyBlue);
}
.OrderTile h3{
    margin: 0;
    padding: 0;
    color: #fff;
}

/* .orderStateIcon,  */
.orderTypeIcon{
    position: absolute;
    width: 2rem;
    height: 2rem;

    /* fill: var(--kurkaNavyBlue);*/
    fill: #fff;
    /* filter: drop-shadow(2px 2px 3px #647189); */
    filter: drop-shadow(2px 2px 3px #fff);
}
/* .orderStateIcon{
    top: 1rem;
    left: 1rem;
} */
.orderTypeIcon{
    top: 1rem;
    top: calc((50% - 1.25rem));
    right: 1rem;
}
.OrderLastUpdate{
    /* position: absolute;
    top: 0;
    right: 4.5rem; */
    /* text-align: right; */
    /* font-size: .8rem; */
    /* color: var(--kurkaNavyBlue); */

    /* border: 1px solid red; */
}



.OrderLastUpdate:hover .time4HumansOriginal {
    display: inline;
}
.OrderLastUpdate:hover .time4HumansFuzzy {
    display: none;
}