#noCurentOrders{
    min-height: 7rem;
    border: .25rem dashed var(--kurkaLightBlue);
    border-radius: 1rem;
    padding: 1rem;
    
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--kurkaLightBlue);
    font-size: 1.2rem;
    text-align: justify;
    text-align-last: center;
}
/* #noCurentOrders .tileIconed h2 @app.css */
#noCurentOrders span{
    display: block;
    max-width: 645px;
}
/* #NewLaundryButtonsWraper{

} */