
.time4Humans{
    display: inline;
    line-height: 1em;
    height: 1em;

    /* border: 1px solid red; */
    padding: .75rem;
    padding-left: 0;
    overflow: hidden;
}

.time4HumansMinutes{
    position: relative;
    font-size: .8em;
    top: -.4em;
    text-decoration: underline;
    margin-left: .25em;
}
.time4HumansFuzzy {
    display: inline;
    position: relative;
    opacity: 1;
    transition: all .3s ease-in-out;
}
.time4HumansOriginal {
    display: none;
    /* transform: translateY(-1.5em); */
    /* opacity: 0; */
    transition: all .3s ease-in-out;
}

.time4Humans:hover .time4HumansOriginal {
    display: inline;
}
.time4Humans:hover .time4HumansFuzzy {
    display: none;
    /* transform: translateY(1.5em);
    opacity: 0; */
}